import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import PrivateRoutes from '../PrivateRoutes';
import { element } from 'prop-types';
import Loadable from 'ui-component/Loadable';

const BlankPage = Loadable(lazy(() => import('views/blank-page')));
const QrSecKey = Loadable(lazy(() => import('views/machines/qr-sec-key')));
const QrLogin = Loadable(lazy(() => import('views/machines/qr-login')));

// ==============================|| DEFAULT ROUTING ||============================== //

const MachinesRoutes = {
    path: '/machines',
    element: [
        <PrivateRoutes key={Math.random()} accessGRole={`${process.env.REACT_APP_GP_MCH}`}>
            <MainLayout key={Math.random()} />
        </PrivateRoutes>
    ],
    children: [
        {
            path: '/machines',
            element: [<></>]
        },
        {
            path: '/machines/security-key',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_MCHQRKEY}`}>
                    <QrSecKey key={Math.random()} />
                 </PrivateRoutes>
            ]
        }
        ,
        {
            path: '/machines/qr-login',
            element: [
                <PrivateRoutes key={Math.random()} accessRole={`${process.env.REACT_APP_RL_MCHQRLOGIN}`}>
                    <QrLogin key={Math.random()} />
                 </PrivateRoutes>
            ]
        }
    ]
};

export default MachinesRoutes;