const qrLogin = {
    id: `qrLogin-${Math.random()}`,
    title: 'User QR Login',
    type: 'item',
    url: `/machines/qr-login`,
    // icon: icons.IconReport,
    breadcrumbs: false,
    // children: transChild,
    role: process.env.REACT_APP_RL_MCHQRLOGIN
};

export default qrLogin;