import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import keycloak from 'services/keycloak';
import checker from 'utils/checker';
import DefaultRoutes from './Group/DefaultRoutes';
import AdminRoutes from './Group/AdminRoutes';
import DashboardRoutes from './Group/DashboardRoutes';
import ReportRoutes from './Group/ReportRoutes';
import PartnerProfile from './Group/PartnerProfileRoutes';
import Transaction from './Group/TransactionReportRoutes';
import Maintenance from './Group/MaintenanceRoutes';
import Financial from './Group/FinancialRoutes';
import MiscellaneousRoutes from './MiscellaneousRoutes';
import RewardsRoutes from './Group/RewardsRoutes';
import SettlementRoutes from './Group/SettlementRoutes';
import MachinesRoutes from './Group/MachinesRoutes';
import ServiceHealthRoutes from './Group/ServiceHealthRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const routeBuilder = [];
    const grp = [
        process.env.REACT_APP_GP_DSD,
        process.env.REACT_APP_GP_ADN,
        process.env.REACT_APP_GP_GNT,
        process.env.REACT_APP_GP_PRT,
        process.env.REACT_APP_GP_TRN,
        process.env.REACT_APP_GP_MNT,
        process.env.REACT_APP_GP_FIN,
        process.env.REACT_APP_GP_RWD,
        process.env.REACT_APP_GP_STL,
        process.env.REACT_APP_GP_MCH,
        process.env.REACT_APP_GP_SH,
    ];

    grp.forEach((grpr) => {
        if (checker.authByGroup(keycloak.token, [grpr])) {
            if (grpr === process.env.REACT_APP_GP_DSD) routeBuilder.push(DashboardRoutes);
            if (grpr === process.env.REACT_APP_GP_ADN) routeBuilder.push(AdminRoutes);
            if (grpr === process.env.REACT_APP_GP_GNT) routeBuilder.push(ReportRoutes);
            if (grpr === process.env.REACT_APP_GP_PRT) routeBuilder.push(PartnerProfile);
            if (grpr === process.env.REACT_APP_GP_TRN) routeBuilder.push(Transaction);
            if (grpr === process.env.REACT_APP_GP_MNT) routeBuilder.push(Maintenance);
            if (grpr === process.env.REACT_APP_GP_FIN) routeBuilder.push(Financial);
            if (grpr === process.env.REACT_APP_GP_RWD) routeBuilder.push(RewardsRoutes);
            if (grpr === process.env.REACT_APP_GP_STL) routeBuilder.push(SettlementRoutes);
            if (grpr === process.env.REACT_APP_GP_MCH) routeBuilder.push(MachinesRoutes);
            if (grpr === process.env.REACT_APP_GP_SH) routeBuilder.push(ServiceHealthRoutes);
        }
    });

    if (routeBuilder.length === 0) {
        routeBuilder.push(DefaultRoutes);
    }
    routeBuilder.push(AuthenticationRoutes);
    routeBuilder.push(MiscellaneousRoutes);
    return useRoutes(routeBuilder, config.basename);
}
