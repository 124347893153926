// assets
import { IconPackages } from '@tabler/icons';
import mchChild from './MchChild';

// constant
const icons = { IconPackages };

const machinesGrp = {
    id: `machinesGrp-${Math.random()}`,
    title: 'Machines',
    type: 'group',
    // url: `/dashboard/default`,
    icon: icons.IconPackages,
    breadcrumbs: false,
    children: mchChild,
    role: process.env.REACT_APP_RL_MCHGRP
};

export default machinesGrp;
